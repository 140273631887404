var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSheet', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Fakturadetaljer"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "py-0",
          on: {
            "click": _vm.openModal
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
      }
    }])
  }, [_vm._v(" Rediger ")])], 1), _c('v-row', _vm._l(_vm.infoFields, function (item, i) {
    return _c('v-col', {
      key: i + '-field',
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "value": item.value,
        "label": item.label
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }