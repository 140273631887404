
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SingleResourceTitle",
  components: { BaseSheet, BaseSheetHeader, BaseSheetField },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  computed: {
    infoFields(): any {
      return [
        // value: getMemberOrganizationName(this, this.organization.orgId),
        {
          value: this.organization?.invoiceDetails?.companyName,
          label: "Bedriftsnavn",
        },
        {
          value: this.organization?.invoiceDetails?.contactName,
          label: "Kontaktperson",
        },
        {
          value: this.organization?.invoiceDetails?.referenceNumber,
          label: "Referanse nr",
        },
        {
          value: this.organization?.invoiceDetails?.numberOfParticipants,
          label: "Antall deltakere",
        },
        {
          value: this.organization?.invoiceDetails?.allowExternalParticipants ? "Ja" : "Nei",
          label: "Tillat eksterne deltagere",
        },
        {
          value: this.organization?.invoiceDetails?.invoiceTo,
          label: "Faktura til",
        },
        {
          value: this.organization?.invoiceDetails?.invoiceAddress,
          label: "Faktura adresse",
        },
        {
          value: this.organization?.invoiceDetails?.invoiceZipCode,
          label: "Post nr",
        },
        {
          value: this.organization?.invoiceDetails?.invoiceZipName,
          label: "Post sted",
        },
      ];
    },
  },
  methods: {
    openModal() {
      const modal = {
        type: "edit",
        headline: "Rediger fakturadetaljer",
      };
      this.$store.commit("hoc/SET_MODAL_TYPE", modal);
      this.$store.commit("hoc/OPEN_MODAL", "Course/CompanyPage/InvoiceDetails");
    },
  },
});
