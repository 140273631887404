var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm.getCourse.courseLocation.areaId ? _c('CourseHeader', {
    attrs: {
      "course": _vm.getCourse
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12"
    }
  }, [_c('InvoiceDetails', {
    attrs: {
      "organization": _vm.getOrganizationInfo
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12"
    }
  }, [_c('OnlineCourse', {
    attrs: {
      "organization": _vm.getOrganizationInfo
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }