var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSheet', {
    staticClass: "d-flex flex-row align-center",
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.navigateBack
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _c('v-card-title', {
    staticClass: "px-5 py-2 justify-space-between flex-grow-1"
  }, _vm._l(_vm.headerInformation, function (courseHeaderItem, index) {
    return _c('div', {
      key: index,
      attrs: {
        "data-cy": "courseHeaderItem-".concat(index)
      }
    }, [_c('span', {
      staticClass: "title font-weight-regular",
      attrs: {
        "color": "primary"
      }
    }, [_vm._v(_vm._s(courseHeaderItem.title) + ": " + _vm._s(courseHeaderItem.value))])]);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }